
import { Options, Vue } from "vue-class-component";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import commaDecimalToDecimal from "@/app/infrastructures/misc/common-library/CommaDecimalToDecimal";
import formatInputMoneyWithDecimal from "@/app/infrastructures/misc/common-library/FormatInputMoneyWithDecimal";

@Options({
  components: {
    UploadFile
  }
})
export default class ModalFormTopupManual extends Vue {
  created() {
    this.resetForm();
    BalanceController.getListBankManual();
  }
  get form() {
    return BalanceController.formTopupManual;
  }

  get payload() {
    return {
      ...this.form,
      amount: commaDecimalToDecimal(this.form.amount.replace(/\./g, ""))
    };
  }

  get isFormValid() {
    return (
      this.payload.fromBankName &&
      this.payload.fromBankAccountName &&
      this.payload.fromBankNumber &&
      this.payload.toBankName.name &&
      this.payload.toBankAccountName &&
      this.payload.toBankNumber &&
      this.payload.amount &&
      this.payload.attachment
    );
  }

  get showTopupUploadForm() {
    return BalanceController.showTopupUploadForm;
  }
  showModalFormTopup(val: boolean) {
    BalanceController.setShowTopupUploadForm(val);
  }

  // select bank to
  get optionBankTo() {
    if (BalanceController.listBankManual?.data.length === 0) {
      return [
        {
          name: "Pilih Bank",
          value: 0
        }
      ];
    }
    return BalanceController.listBankManual?.data.map(e => {
      return {
        ...e,
        name: e.internalBankAccountName,
        value: e.internalBankAccountId
      };
    });
  }

  formatNumber(value: string) {
    return value.replace(/\D+/g, "");
  }

  formatInputMoneyWithDecimal(val: string) {
    return formatInputMoneyWithDecimal(val);
  }

  selectBankTo(item: any) {
    this.form.toBankAccountName = item.internalBankAccountAccountName;
    this.form.toBankNumber = item.internalBankAccountAccountNumber;
  }

  // confirmation
  showConfirmModal = false;
  get isSuccessSubmit() {
    return BalanceController.isSuccessSubmit;
  }
  showCloseConfirmModal = false;
  get isLoading() {
    return BalanceController.isLoading;
  }
  get errorCause() {
    return BalanceController.errorCause;
  }

  setSuccessSubmit(val: boolean) {
    BalanceController.setSuccessSubmit(val);
  }

  setErrorSubmit(val: boolean) {
    BalanceController.setError(val);
  }

  //save
  onSubmit() {
    this.showConfirmModal = false;
    BalanceController.addTopupManual({
      amount: this.payload.amount,
      senderBankName: this.payload.fromBankName,
      senderBankAccountName: this.payload.fromBankAccountName,
      senderBankAccountNumber: this.payload.fromBankNumber,
      recepientBankName: this.payload.toBankName.name,
      recepientBankAccountName: this.payload.toBankAccountName,
      recepientBankAccountNumber: this.payload.toBankNumber,
      attachment: this.payload.attachment
    });
  }

  //reset form
  resetForm() {
    this.showCloseConfirmModal = false;
    BalanceController.resetFormTopupManual();
  }
}
